<template>
  <div>
    <el-dialog width="60%" :title="!dataForm.id ? '新增' : '查看'" :close-on-click-modal="false" :visible.sync="visible"
               :before-close="handleCloseDialog">
      <el-form  :model="dataForm" ref="dataForm" label-width="160px">

        <el-form-item label="角色:" >
          <el-select v-model="dataForm.virUid" placeholder="角色">
            <el-option v-for="item in virtualUser" :key="item.uid" :label="item.nickname" :value="String(item.uid)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人物描述:" prop="virDesc">
          <el-input  type="textarea" placeholder="请输入内容" v-model="dataForm.virDesc"
                    class="input-width" resize="none" :rows="3">
          </el-input>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-radio-group v-model="dataForm.status">
            <el-radio :label="0" >正常</el-radio>
            <el-radio :label="1">禁止</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
         <el-button @click="handleCloseDialog">取消</el-button>
         <el-button type="primary" @click="beforeSubmit()">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { langOpt } from "@/views/common/commonOpt";
export default {
  data() {
    return {
      id: '',
      virtualUser: [],
      visible: false,
      dataForm: {
        virUid: '',
        virDesc: '',
        status: 0,
        id:''
      },
      langOpt: langOpt,
      dataRule: {

      },
    }
  },
  async created() {
    this.virtualUser = await this.$store.dispatch("GetVirtualUser", {});

  },
  methods: {
    init(row) {
      // console.log('row', row)
      if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()
      this.id = !row ? 0 : row.id
      this.dataForm.id = row.id
      if (row === 0) {
        this.dataForm.status = 0
        this.dataForm.virDesc = ''
        this.dataForm.virUid = ''
        // 新增模式
        this.visible = true
        return;
      }

      this.visible = true
      this.dataForm.virDesc = row.virDesc
      this.dataForm.virUid = row.virUid
      this.dataForm.status = row.status
    },

    beforeSubmit() {
      this.$confirm('当前配置信息是否已确认无误?', '提示', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.dataFormSubmit();
      })
    },

    handleCloseDialog() {
      this.visible = false
      this.imageList = []
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          console.log('dataForm', this.dataForm)

          this.$http({
            url: this.$http.adornUrl(`/admin/teleVirUser/add`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm,
              id: this.id
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-width {
  width: 35vw;
  margin-bottom: 2vh;
}
</style>